import type { SearchConfig } from '@config/search/type';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import { TRANSACTION_VARIANT } from '@type/search/transactionVariant';

export const SEARCH_BOX_ROW_LAYOUT: SearchConfig['searchBoxRows'] = {
    secondary: {
        [TRANSACTION_VARIANT.flatSell]: {
            default: '1fr 1fr 370px',
        },
        [TRANSACTION_VARIANT.flatRent]: {
            default: '1fr 1fr 370px',
        },
        [TRANSACTION_VARIANT.studioFlatSell]: {
            default: '1fr 1fr 320px 352px',
        },
        [TRANSACTION_VARIANT.studioFlatRent]: {
            default: '1fr 1fr 370px',
        },
        [TRANSACTION_VARIANT.houseSell]: {
            default: '1fr 1fr 370px',
        },
        [TRANSACTION_VARIANT.houseRent]: {
            default: '1fr 1fr 370px',
        },
        [TRANSACTION_VARIANT.investment]: {
            default: '1fr 1fr 370px',
        },
        [TRANSACTION_VARIANT.roomRent]: {
            default: '1fr 1fr 340px',
        },
        [TRANSACTION_VARIANT.terrainSell]: {
            default: '1fr 1fr',
        },
        [TRANSACTION_VARIANT.terrainRent]: {
            default: '1fr 1fr',
        },
        [TRANSACTION_VARIANT.commercialPropertySell]: {
            default: '1fr 1fr 320px 352px',
        },
        [TRANSACTION_VARIANT.commercialPropertyRent]: {
            default: '1fr 1fr',
        },
        [TRANSACTION_VARIANT.hallSell]: {
            default: '1fr 1fr 320px 352px',
        },
        [TRANSACTION_VARIANT.hallRent]: {
            default: '1fr 1fr',
        },
        [TRANSACTION_VARIANT.garageSell]: {
            default: '1fr 1fr 320px 352px',
        },
        [TRANSACTION_VARIANT.garageRent]: {
            default: '1fr 1fr',
        },
    },
};

export const SEARCH_BOX_FIELDS_LAYOUT: SearchConfig['searchBoxFieldsLayout'] = {
    [TRANSACTION_VARIANT.flatSell]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.area,
                SEARCH_FORM_UNIVERSAL_FIELD.roomsNumber,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.floors,
                SEARCH_FORM_UNIVERSAL_FIELD.floorsNumber,
                SEARCH_FORM_UNIVERSAL_FIELD.pricePerMeter,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.buildingType,
                SEARCH_FORM_UNIVERSAL_FIELD.buildYear,
                SEARCH_FORM_UNIVERSAL_FIELD.buildingMaterial,
                SEARCH_FORM_UNIVERSAL_FIELD.ownerTypeSingleSelect,
                SEARCH_FORM_UNIVERSAL_FIELD.market,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.flatRent]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.area,
                SEARCH_FORM_UNIVERSAL_FIELD.roomsNumber,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.floors,
                SEARCH_FORM_UNIVERSAL_FIELD.floorsNumber,
                SEARCH_FORM_UNIVERSAL_FIELD.buildingType,
                SEARCH_FORM_UNIVERSAL_FIELD.buildYear,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.freeFrom,
                SEARCH_FORM_UNIVERSAL_FIELD.heating,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.studioFlatSell]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.ownerTypeSingleSelect,
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.area,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.floors,
                SEARCH_FORM_UNIVERSAL_FIELD.floorsNumber,
                SEARCH_FORM_UNIVERSAL_FIELD.pricePerMeter,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.buildingType,
                SEARCH_FORM_UNIVERSAL_FIELD.buildYear,
                SEARCH_FORM_UNIVERSAL_FIELD.buildingMaterial,
                SEARCH_FORM_UNIVERSAL_FIELD.market,
                SEARCH_FORM_UNIVERSAL_FIELD.advertId,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.studioFlatRent]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [SEARCH_FORM_UNIVERSAL_FIELD.price, SEARCH_FORM_UNIVERSAL_FIELD.area, SEARCH_FORM_UNIVERSAL_FIELD.freeFrom],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.floors,
                SEARCH_FORM_UNIVERSAL_FIELD.floorsNumber,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.buildingType,
                SEARCH_FORM_UNIVERSAL_FIELD.buildYear,
                SEARCH_FORM_UNIVERSAL_FIELD.heating,
                SEARCH_FORM_UNIVERSAL_FIELD.advertId,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
                SEARCH_FORM_UNIVERSAL_FIELD.media,
            ],
        ],
    },
    [TRANSACTION_VARIANT.houseSell]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.area,
                SEARCH_FORM_UNIVERSAL_FIELD.roomsNumber,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.terrainArea,
                SEARCH_FORM_UNIVERSAL_FIELD.pricePerMeter,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.buildingType,
                SEARCH_FORM_UNIVERSAL_FIELD.buildYear,
                SEARCH_FORM_UNIVERSAL_FIELD.buildingMaterial,
                SEARCH_FORM_UNIVERSAL_FIELD.ownerTypeSingleSelect,
                SEARCH_FORM_UNIVERSAL_FIELD.market,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.houseRent]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.area,
                SEARCH_FORM_UNIVERSAL_FIELD.roomsNumber,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.terrainArea,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.buildingType,
                SEARCH_FORM_UNIVERSAL_FIELD.buildYear,
                SEARCH_FORM_UNIVERSAL_FIELD.freeFrom,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.investment]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.investmentEstateType],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.area,
                SEARCH_FORM_UNIVERSAL_FIELD.roomsNumber,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.developerName,
                SEARCH_FORM_UNIVERSAL_FIELD.investmentName,
                SEARCH_FORM_UNIVERSAL_FIELD.investmentState,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.roomRent]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.freeFrom,
                SEARCH_FORM_UNIVERSAL_FIELD.peoplePerRoom,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.advertId,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
                SEARCH_FORM_UNIVERSAL_FIELD.media,
            ],
        ],
    },
    [TRANSACTION_VARIANT.terrainSell]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [SEARCH_FORM_UNIVERSAL_FIELD.price, SEARCH_FORM_UNIVERSAL_FIELD.area, SEARCH_FORM_UNIVERSAL_FIELD.plotType],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.pricePerMeter,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.advertId,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
                SEARCH_FORM_UNIVERSAL_FIELD.vicinity,
            ],
        ],
    },
    [TRANSACTION_VARIANT.terrainRent]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [SEARCH_FORM_UNIVERSAL_FIELD.price, SEARCH_FORM_UNIVERSAL_FIELD.area, SEARCH_FORM_UNIVERSAL_FIELD.plotType],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.advertId,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.commercialPropertySell]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.area,
                SEARCH_FORM_UNIVERSAL_FIELD.roomsNumber,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.floors,
                SEARCH_FORM_UNIVERSAL_FIELD.pricePerMeter,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.ownerTypeSingleSelect,
                SEARCH_FORM_UNIVERSAL_FIELD.market,
                SEARCH_FORM_UNIVERSAL_FIELD.advertId,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.useTypes,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.commercialPropertyRent]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [SEARCH_FORM_UNIVERSAL_FIELD.price, SEARCH_FORM_UNIVERSAL_FIELD.area, SEARCH_FORM_UNIVERSAL_FIELD.freeFrom],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.floors,
                SEARCH_FORM_UNIVERSAL_FIELD.pricePerMeter,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.advertId,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.useTypes,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.hallSell]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.area,
                SEARCH_FORM_UNIVERSAL_FIELD.structure,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.pricePerMeter,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.height,
                SEARCH_FORM_UNIVERSAL_FIELD.heating,
                SEARCH_FORM_UNIVERSAL_FIELD.ownerTypeSingleSelect,
                SEARCH_FORM_UNIVERSAL_FIELD.market,
                SEARCH_FORM_UNIVERSAL_FIELD.advertId,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.useTypes,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.hallRent]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.area,
                SEARCH_FORM_UNIVERSAL_FIELD.structure,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.height,
                SEARCH_FORM_UNIVERSAL_FIELD.heating,
                SEARCH_FORM_UNIVERSAL_FIELD.advertId,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.useTypes,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.garageSell]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.price,
                SEARCH_FORM_UNIVERSAL_FIELD.area,
                SEARCH_FORM_UNIVERSAL_FIELD.ownerTypeSingleSelect,
            ],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.market,
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.advertId,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
    [TRANSACTION_VARIANT.garageRent]: {
        default: [
            [SEARCH_FORM_UNIVERSAL_FIELD.estate, SEARCH_FORM_UNIVERSAL_FIELD.transaction],
            [SEARCH_FORM_UNIVERSAL_FIELD.price, SEARCH_FORM_UNIVERSAL_FIELD.area, SEARCH_FORM_UNIVERSAL_FIELD.advertId],
            [
                SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated,
                SEARCH_FORM_UNIVERSAL_FIELD.description,
                SEARCH_FORM_UNIVERSAL_FIELD.extras,
            ],
        ],
    },
};
