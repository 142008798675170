import { SEARCH_BOX_FIELDS_LAYOUT, SEARCH_BOX_ROW_LAYOUT } from '@config/consts/searchBox/layout/search/storiaro';
import type { SearchConfig } from '@config/search/type';

export const SEARCH_CONFIG: SearchConfig = {
    isSaveSearchClicktipEnabled: true,
    searchBoxRows: SEARCH_BOX_ROW_LAYOUT,
    searchBoxFieldsLayout: SEARCH_BOX_FIELDS_LAYOUT,
    areLocationObjectBreadcrumbsEnabled: true,
    seo: {
        studioFlatLabels: ['garsoniera', 'garsoniere'],
        metaDataVariants: ['primaryMarket', 'investmentEstate'],
        internalSeoLinks: {
            propertiesForSell: [
                'FLAT',
                'STUDIO_FLAT',
                'HOUSE',
                'TERRAIN',
                'COMMERCIAL_PROPERTY',
                'OFFICE',
                'HALL',
                'VILLAS',
                'PENTHOUSES',
            ],
            propertiesForRent: [
                'FLAT',
                'STUDIO_FLAT',
                'HOUSE',
                'ROOM',
                'TERRAIN',
                'COMMERCIAL_PROPERTY',
                'OFFICE',
                'HALL',
                'VILLAS',
                'PENTHOUSES',
            ],
            propertiesFromPrimaryMarket: ['FLAT', 'STUDIO_FLAT', 'HOUSE'],
            estatesWithRelatedNumberOfRoomsSearches: ['FLAT', 'HOUSE'],
        },
        queryParametersEnabledForIndexing: ['page', 'description'],
    },
    defaultBoundingBoxForMap: {
        neLat: 48.680081145641076,
        neLng: 30.00366250737512,
        swLat: 43.32118195208092,
        swLng: 19.599609873285345,
    },
    vectorMapId: 'da63db138f458e00',
    locationLevelLikeCity: [
        'county_capital',
        'municipality',
        'town',
        'county', // only for Bucharest
    ],
    locationLevelLikeDistrictAndSubdistrict: ['district', 'sector'],
    locationLevelLikeDistrict: ['district', 'sector'],
    locationLevelLikeSubdistrict: ['district'], // only for Bucharest
    shouldShowSearchCriteriaOnSaveSearchModal: true,
    shouldShowSaveSearchPrompt: true,
    isSpecialOfferForUnitsExperimentEnabled: false,
    isPopularSearchesSectionEnabled: true,
};
